import React, { useEffect } from "react";
import Section from "../components/Section/Section";
import Template from "../components/Template/Template";
import { trackEvent } from "../utils/tealium";
import { capitalizeEachWordOfPathname } from "../utils/capitalisePathname";
import { Button } from "react-bootstrap";
import logo from "../../static/images/svg/logo-white.svg";

export const InstructorNotFound = props => {
  useEffect(() => {
    trackEvent({
      eventCategory: "404",
      eventAction: "Pageload",
      eventLabel: document.referrer,
    });
  }, []);

  const seo = { title: "BSM - Instructor not found" };

  return (
    <Template seo={seo}>
      <div className="InstructorNotFound">
        <Section className="bg-bsm-grey-6 top-fill">
          <div className="Hero">
            <img src={logo} className="footer-logo" alt="logo" />
          </div>
        </Section>
        <Section className="bg-bsm-primary-white btrr">
          <div class="page-width-container container-fluid">
            <div class="section-heading justify-content-center row">
              <div class="col-sm-8 col-12">
                <h1>Oops, this instructor can’t be found.</h1>
              </div>
              <div class="col-sm-12 col-12">
                <p class="lead">Find other instructors in your area below.</p>
              </div>
            </div>
            <div class="justify-content-center row"></div>
          </div>
          <Button
            className="SearchBtn"
            variant="secondary"
            href="/locations/"
            onClick={() => {
              trackEvent({
                eventCategory: capitalizeEachWordOfPathname(
                  window.location.pathname
                ),
                eventAction: "Button Click",
                eventLabel: "Search locations",
              });
            }}
          >
            Search locations
          </Button>
        </Section>
      </div>
    </Template>
  );
};

export default InstructorNotFound;
